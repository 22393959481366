
<template lang = "pug">
    component(:is = "layout")
      router-view
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"
import BlankLayout  from "@/layouts/blank"
import DefaultLayout  from "@/layouts/default"
import { mapGetters, mapActions } from "vuex"

export default {
  name      : "ThemisReporter",
  components: { BlankLayout, DefaultLayout, ValidationProvider, ValidationObserver },
  data      : () => ({ }),
  computed  : {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn"
    }),
    layout() {
      let layout = this.$route.meta.layout
      if (layout) {
        if (this.$vuetify.breakpoint.smAndDown && this.$route.meta.mobileLayout) {
          layout = this.$route.meta.mobileLayout
        }
        return `${layout}Layout`
      }
      return "BlankLayout"
    }
  },
  watch: {
    isLoggedIn: {
      immediate: false,
      handler  : function(newValue) {
        if (!newValue && this.$route.name !== "logout") {
          this.$router.push({
            name  : "logout",
            params: {
              locale : this.$route.params.locale || "en-GB",
              channel: this.$route.params.channel
            }
          })
        }
      }
    },
    "$route.query.microcopy": {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && process.env.VUE_APP_THEMIS_FRANKFURT_API_BASE_URL.includes("develop")) {
          this.$i18n.locale = this.$route.query.microcopy
        }
      }
    }
  },
  methods: {
    ...mapActions({
      notify: "shared/notify"
    })
  }
}
</script>
