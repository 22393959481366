/**
 * @file It contains all the action methods which are used to mutate state asynchronously
 */
import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "messages"

export default {
  /**
   * This action will load messages.
   * @param {*} context it is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadMessages(context, payload) {
    context.commit("setLoadingMessages", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getMessagesResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getMessagesResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateMessages", getMessagesResponse.data)
      } else {
        context.commit("setMessages", getMessagesResponse.data)
      }
    } else {
      // something went wrong while getting messages
    }

    context.commit("setLoadingMessages", false)
  },

  /**
   * This action will add a message.
   * @param {*} context is the store.
   */
  async addMessage(context, payload) {
    context.commit("setAddingMessage", true)
    context.commit("setMessageAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postMessageResponse = await httpClient.post(undefined, payload)

    if (postMessageResponse.status === HTTP_STATUS_CODE.OK) {
      context.commit("updateMessages", [postMessageResponse.data])
      context.commit("setMessageAdded", true)
    } else if (postMessageResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setMessageAddError", postMessageResponse.data)
    } else {
      // something went wrong while adding a message
    }
    context.commit("setAddingMessage", false)
  },

  /**
   * This action will update specific message
   * @param {*} context it is the store.
   * @param {*} id is the message id.
   * @param {*} data it is the message details
   */
  async updateMessage(context, payload) {
    const id = payload.id
    delete payload.id
    context.commit("setUpdatingMessage", true)
    context.commit("setMessageUpdated", false)
    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const putMessageResponse = await httpClient.put(`/${id}`, payload)
    if (putMessageResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("updateMessages", [{
        id,
        ...payload
      }])
      context.commit("setMessageUpdated", true)
    } else {
      context.dispatch("shared/notify", {
        type: "error",
        text: "NOTIFICATION_UNEXPECTED_ERROR"
      }, { root: true })
    }
    context.commit("setUpdatingMessage", false)
  },

  /**
   * This action removes a particular message.
   * @param {*} context is the store.
   * @param {*} payload contains id of the message.
   */
  async removeMessage(context, payload) {
    context.commit("setMessageRemoved", false)
    context.commit("setRemovingMessage", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteMessageResponse = await httpClient.delete(`/${payload}`)

    if (deleteMessageResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeMessages", [{
        id: payload
      }])
      context.commit("setMessageRemoved", true)
    } else {
      // something went wrong while updating an message
    }

    context.commit("setRemovingMessage", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setMessages", new Array())
    context.commit("setLoadingMessages", undefined)
    context.commit("setMessageAddError", undefined)
    context.commit("setAddingMessage", false)
    context.commit("setMessageAdded", false)
    context.commit("setMessageUpdated", false)
    context.commit("setUpdatingMessage", false)
  }
}