import Vue from "vue"
import Vuetify from "vuetify/lib"
import "@mdi/font/css/materialdesignicons.css"
import "nunito-fontface/css/nunito/nunito-fontface.css"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          base   : "#006646",
          darken3: "#003026"
        },
        secondary: "#00B687",
        accent   : {
          base    : "#7986CB",
          darken1 : "#9A8EB0",
          darken3 : "#676C7D",
          lighten1: "#EDE0FF",
          lighten2: "#FFFDFF"
        },
        success: {
          base    : "#00BFA5",
          darken1 : "#00A48B",
          darken3 : "#016E59",
          lighten2: "#63F8DC",
          lighten3: "#83FFF8"
        },
        error: {
          base    : "#B71C1C",
          darken2 : "#980006",
          lighten2: "#F75C4A",
          lighten5: "#FFAF95"
        },
        warning: {
          base    : "#FFA000",
          darken2 : "#C06C04",
          lighten1: "#FFBB30",
          lighten2: "#FFD64E"
        },
        info: {
          base    : "#757575",
          darken1 : "#454545",
          darken4 : "#1B1B1B",
          lighten1: "#8F8F8F",
          lighten2: "#A9A9A9",
          lighten3: "#C4C4C4",
          lighten4: "#E0E0E0"
        }
      },
      dark: {
        primary: {
          base   : "#006646",
          darken3: "#003026"
        },
        secondary: "#00B687",
        accent   : {
          base    : "#7986CB",
          darken1 : "#9A8EB0",
          darken3 : "#676C7D",
          lighten1: "#EDE0FF",
          lighten2: "#FFFDFF"
        },
        success: {
          base    : "#00BFA5",
          darken1 : "#00A48B",
          darken3 : "#016E59",
          lighten2: "#63F8DC",
          lighten3: "#83FFF8"
        },
        error: {
          base    : "#B71C1C",
          darken2 : "#980006",
          lighten2: "#F75C4A",
          lighten5: "#FFAF95"
        },
        warning: {
          base    : "#FFA000",
          darken2 : "#C06C04",
          lighten1: "#FFBB30",
          lighten2: "#FFD64E"
        },
        info: {
          base    : "#757575",
          darken1 : "#454545",
          darken4 : "#1B1B1B",
          lighten1: "#8F8F8F",
          lighten2: "#A9A9A9",
          lighten3: "#C4C4C4",
          lighten4: "#E0E0E0"
        }
      }
    }
  },
  icons: {
    iconfont: "mdi"
  }
})
