/**
 * @file It contains vuex token module mutations to mutate the state variables.
 */
export default {
  setPageFreezed: (state, pageFreeze) => {
    state.pageFreeze = pageFreeze
  },
  setNotify: (state, notify) => {
    state.notify = notify
  },
  setConfigurations: (state, configurations) => {
    state.configurations = configurations
  },
  setClientName: (state, clientName) => {
    state.clientName = clientName
  },
  setBreadcrumbs: (state, breadcrumbs) => {
    state.breadcrumbs = breadcrumbs
  },
  setNotification: (state, notification) => {
    state.notification = notification
  },
  setUnexpectedError: (state, unexpectedError) => {
    state.unexpectedError = unexpectedError
  }
}