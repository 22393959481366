/**
 * @file It contains vuex messages module mutations to mutate the state variables.
 */
export default {
  setLoadingMessages: (state, messages) => {
    state.loadingMessages = messages
  },
  setAddingMessage: (state, addingMessage) => {
    state.addingMessage = addingMessage
  },
  setMessageAdded: (state, messageAdded) => {
    state.messageAdded = messageAdded
  },
  setMessages: (state, messages) => {
    state.messages = messages
  },
  updateMessages: (state, messages) => {
    for (const message of messages) {
      const index = state.messages.findIndex(stateMessage => stateMessage?.id === message.id)
      if (index >= 0) {
        state.messages.splice(index, 1, { ...state.messages[index], ...message })
      } else {
        state.messages.push(message)
      }
    }
  },
  setMessageAddError: (state, messageAddError) => {
    state.messageAddError = messageAddError
  },
  setUpdatingMessage: (state, updatingMessage) => {
    state.updatingMessage = updatingMessage
  },
  setMessageUpdated: (state, messageUpdated) => {
    state.messageUpdated = messageUpdated
  },
  setRemovingMessage: (state, removingMessage) => {
    state.removingMessage = removingMessage
  },
  setMessageRemoved: (state, messageRemoved) => {
    state.messageRemoved = messageRemoved
  },
  removeMessages: (state, messages) => {
    for (const message of messages) {
      const index = state.messages.findIndex(stateMessage => stateMessage?.id === message.id)
      if (index >= 0) {
        state.messages.splice(index, 1)
      }
    }
  }
}