/**
 * @file It contains vuex token module getter methods to access store variables.
 */
export default {
  isPageFreezed  : state => state.pageFreeze,
  isNotified     : state => state.notify,
  configurations : state => state.configurations,
  clientName     : state => state.clientName,
  breadcrumbs    : state => state.breadcrumbs,
  notification   : state => state.notification,
  unexpectedError: state => state.unexpectedError
}