import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "form-templates"

export default {
  /**
   * This action will load form templates.
   * @param {*} context is the store.
   * @param {*} payload is the filter to load form templates.
   */
  async loadFormTemplates(context, payload) {
    context.commit("setLoadingFormTemplates", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getFormTemplatesResult = await httpClient.get(undefined, {
      params: payload
    })

    if (getFormTemplatesResult.status === HTTP_STATUS_CODE.OK) {
      context.commit("setFormTemplates", getFormTemplatesResult.data)
    }

    context.commit("setLoadingFormTemplates", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setLoadingFormTemplates", undefined)
    context.commit("setFormTemplates", new Array())
  }
}