import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "reporter-survey"

/**
   * This action will upload reporter survey to S3.
   * @param {*} context is the store.
   * @param {*} payload contains reporter answers to survey.
   */

export default {
  async addSurvey(context, payload) {
    const { isAuthPublic, surveyResponses } = payload
    context.commit("setAddingSurvey", true)
    context.commit("setSurveyAdded", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path), isAuthPublic
    }, context)

    const postSurveyResponse = await httpClient.post(undefined, surveyResponses)

    if (postSurveyResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("setSurveyAdded", true)
    }
    context.commit("setAddingSurvey", false)
  },

  /*
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setAddingSurvey", undefined)
    context.commit("setSurveyAdded", false)
  }
}
