/**
 * @file This is the entry file for themis reporter operations vue front end application.
 * All the plugins should be loaded here before the new vue instance is created and mounted.
 */
import Vue from "vue"
import "@/plugins/constants"
import "@/plugins/registerServiceWorker"
import "@/plugins/moment"
import "@/plugins/filters"
import "@/plugins/validations"
import App from "@/App.vue"
import i18n from "@/plugins/i18n"
import router from "@/plugins/router"
import store from "@/plugins/vuex"
import vuetify from "@/plugins/vuetify"

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app")