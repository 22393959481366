/**
 * @file It contains scripts for languages selection Component
 */
export default {
  name: "LanguagesSelection",
  data: () => ({
  }),
  props: {
    pChannelLanguages: Array,
    pReporterLanguage: Object
  },
  computed: {
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    sortedChannelLanguages() {
      const languages = [...this.pChannelLanguages]
      if (this.pReporterLanguage) {
        languages.sort((first, second) => first.name.localeCompare(second.name)).forEach((language, index) => {
          if (language.id === this.pReporterLanguage.id) {
            languages.splice(index, 1)
            languages.unshift(this.pReporterLanguage)
          }
        })
      }
      return languages
    }
  },
  methods: {
    getTextSizeClassForLanguage(language) {
      const languageText = `${language.name} - ${language.localName}`
      if (languageText.length > 25) {
        if (languageText.length > 35) {
          if (this.isMobileView) {
            return "caption"
          } else {
            return "body-2"
          }
        }
        if (this.isMobileView) {
          return "body-2"
        }
      }
    }
  }
}