import frowningFaceSvg from "@/assets/images/survey/frowning-face.svg"
import neutralFaceSvg from "@/assets/images/survey/neutral-face.svg"
import smilingFaceSvg from "@/assets/images/survey/smiling-face-with-smiling-eyes.svg"
import grinningFaceSvg from "@/assets/images/survey/grinning-face-with-smiling-eyes.svg"
import neutralFaceOrangeSvg from "@/assets/images/survey/neutral-face-orange.svg"
import frowningFaceRedSvg from "@/assets/images/survey/frowning-face-red.svg"
import smilingFaceLightGreenSvg from "@/assets/images/survey/smiling-face-with-smiling-eyes-light-green.svg"
import grinningFaceGreenSvg from "@/assets/images/survey/grinning-face-with-smiling-eyes-green.svg"

export default {
  name : "Survey",
  props: {
    pShowSurvey    : Boolean,
    pIsMobileView  : Boolean,
    pIsSurveyAdded : Boolean,
    pSurveySent    : Boolean,
    pIsAddingSurvey: Boolean
  },
  data() {
    return {
      hoveredIndex: null,
      svgs        : [
        frowningFaceSvg,
        neutralFaceSvg,
        smilingFaceSvg,
        grinningFaceSvg
      ],
      hoveredSvgs: [
        frowningFaceRedSvg,
        neutralFaceOrangeSvg,
        smilingFaceLightGreenSvg,
        grinningFaceGreenSvg
      ],
      currentQuestionIndex  : 0,
      progressValue         : 15,
      submittedAnswer       : null,
      surveyResponses       : [],
      displayThankyouMessage: false
    }
  },
  computed: {
    addingSurveyResponse() {
      return !this.submittedAnswer?.length
    }
  },
  methods: {
    surveyQuestionAnswers(index) {
      const surveyData = [
        {
          question         : this.$t("149"),
          questionInEnglish: this.$t("149", "en-GB"),
          answersInEnglish : ["Dislike a lot", "Dislike", "Like", "Like a lot"]
        },
        {
          question         : this.$t("150"),
          questionInEnglish: this.$t("150", "en-GB"),
          answers          : [this.$t("151"), this.$t("152"), this.$t("153"), this.$t("154")],
          answersInEnglish : [this.$t("151", "en-GB"), this.$t("152", "en-GB"), this.$t("153", "en-GB"), this.$t("154", "en-GB")]
        },
        {
          question         : this.$t("155"),
          questionInEnglish: this.$t("155", "en-GB"),
          answers          : [this.$t("156"), this.$t("157"), this.$t("158"), this.$t("159")],
          answersInEnglish : [this.$t("156", "en-GB"), this.$t("157", "en-GB"), this.$t("158", "en-GB"), this.$t("159", "en-GB")]
        },
        {
          question         : this.$t("160"),
          questionInEnglish: this.$t("160", "en-GB"),
          answers          : [this.$t("161"), this.$t("162"), this.$t("163"), this.$t("164")],
          answersInEnglish : [this.$t("161", "en-GB"), this.$t("162", "en-GB"), this.$t("163", "en-GB"), this.$t("164", "en-GB")]
        },
        {
          question         : this.$t("165"),
          questionInEnglish: this.$t("165", "en-GB")
        }
      ]
      return surveyData[index]
    },
    nextQuestion(selectedOptionIndex) {
      this.surveyResponses.push({
        question: this.surveyQuestionAnswers(this.currentQuestionIndex).questionInEnglish,
        answer  : this.surveyQuestionAnswers(this.currentQuestionIndex).answersInEnglish[selectedOptionIndex]
      })

      this.currentQuestionIndex++
      this.progressValue  += 15
      this.submittedAnswer = null
    },
    logout() {
      this.displayThankyouMessage = false
      this.$emit("logout", this.surveyResponses)
    },
    handleClickOutsideEvent() {
      if (this.displayThankyouMessage) {
        this.logout()
      }
    },

    submitSurveyResponse() {
      this.surveyResponses.push({
        question: this.surveyQuestionAnswers(this.currentQuestionIndex).questionInEnglish,
        answer  : this.submittedAnswer
      })

      this.$emit("submitSurveyResponse", this.surveyResponses)
    }
  },
  watch: {
    pIsSurveyAdded: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.surveyResponses      = []
          this.currentQuestionIndex = 0
          this.progressValue        = 15
          this.hoveredIndex         = null
          this.submittedAnswer      = null

          if (this.pSurveySent) {
            this.displayThankyouMessage = true
          }
        }
      }
    }
  }
}