/**
 * @file It contains decision constants that are used to pass to themis-reporter-decision component.
 */
export default {
  SEND_MESSAGE: {
    pCardProps: {
      class: ["pa-2", "pt-8"]
    },
    pActions: [{
      text       : "56",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "info",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "57",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  END_SESSION: {
    pCardProps: {
      class: ["pa-2", "pt-8"]
    },
    pActions: [{
      text       : "88",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "info",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "89",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  },
  DISCARD_MESSAGE: {
    pCardProps: {
      class: ["pa-2", "pt-8"]
    },
    pActions: [{
      text       : "88",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "no",
      buttonProps: {
        color  : "info",
        block  : true,
        text   : true,
        loading: false
      }
    }, {
      text       : "118",
      class      : ["col-6", "col-md-4", "pl-4"],
      value      : "yes",
      buttonProps: {
        color  : "primary",
        block  : true,
        loading: false
      }
    }]
  }
}