/**
 * @file It contains all the vuex reports module state variables.
 */
export default {
  loadingReports          : undefined,
  reports                 : new Array(),
  addingReport            : undefined,
  reportAdded             : undefined,
  updatingReportFormValues: undefined,
  reportFormValuesUpdated : undefined,
  sendingReport           : undefined,
  reportSent              : undefined,
  reportFormValues        : new Object(),
  consentGiven            : null
}