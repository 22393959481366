import axios from "axios"
import { HTTP_STATUS_CODE, ERROR } from "@/constants"
import { getSession, getCurrentUser } from "@/utils/cognito"
import { getBaseURL } from "@/utils"

export const GET_HTTP_CLIENT = (config, store) => {
  const httpClient       = axios.create(config)
  const themisApiBaseUrl = getBaseURL(store.rootGetters["auth/region"])

  if (config && config.baseURL && config.baseURL.includes(themisApiBaseUrl)) {
    httpClient.interceptors.request.use(async config => {
      config["content-type"] = "application/json"
      config.headers.client  = store.rootGetters["auth/clientName"]

      if (!config.auth) {
        const baseURL                          = config.baseURL
        const url                              = config.url
        const isReporterSurveyPublicURL        = baseURL.endsWith("v1/reporter-survey") && config.isAuthPublic
        const isConfigurationsUrl              = baseURL.endsWith("v1/configurations")
        const isGetRegionURL                   = baseURL.endsWith("v1/getRegion")
        const isChannelsURL                    = baseURL.endsWith("v1/channels")
        const isLanguagesURL                   = baseURL.endsWith("v1/languages")
        const isGetRequest                     = config.method === "get"
        const isPostRequest                    = config.method === "post"
        const isPostPrivacyPolicyDownloadURL   = url && url.endsWith("privacy-policy/dl-url") && isPostRequest
        const isTranslationConfigurationOrLogo = !url || url.endsWith("translation-configurations") || url.endsWith("logo")

        if (isReporterSurveyPublicURL || isPostPrivacyPolicyDownloadURL
          || ((isConfigurationsUrl || isChannelsURL || isLanguagesURL || isGetRegionURL)
          && isGetRequest && isTranslationConfigurationOrLogo)
        ) {
          config.auth = {
            username: "public"
          }
        } else {
          const reporterUserPoolId       = store.rootGetters["auth/reporterUserPoolId"]
          const reporterUserPoolClientId = store.rootGetters["auth/reporterUserPoolClientId"]
          const cognitoUser              = getCurrentUser(reporterUserPoolId, reporterUserPoolClientId)

          if (cognitoUser) {
            const session = await getSession(reporterUserPoolId, reporterUserPoolClientId)

            if (session) {
              config.headers.Authorization = `Bearer ${session.getIdToken().getJwtToken()}`
            } else {
              store.commit("auth/setLoggedIn", false, { root: true })
            }
          }
        }
      }
      return config
    })
    httpClient.interceptors.response.use(response => {
      return Promise.resolve({
        status: response.status,
        data  : response.data
      })
    }, async error => {
      if (error.response) {
        if (error.response.status === HTTP_STATUS_CODE.FORBIDDEN &&
          error.response.data.name === "TokenExpiredError"
        ) {
          const reporterUserPoolId       = store.rootGetters["auth/reporterUserPoolId"]
          const reporterUserPoolClientId = store.rootGetters["auth/reporterUserPoolClientId"]
          const session                  = await getSession(reporterUserPoolId, reporterUserPoolClientId)
          if (session) {
            error.config.headers["Authorization"] = `Bearer ${session.getIdToken().getJwtToken()}`
            return axios.request(error.config)
          } else {
            store.commit("auth/setLoggedIn", false, { root: true })
          }
        } else {
          const response = {
            status: error.response.status,
            data  : error.response.data
          }
          if (response.status === HTTP_STATUS_CODE.CONFLICT) {
            const message = error.response.data.detail
            const field   = message.substring(message.indexOf("(") + 1, message.indexOf(")")).replace(/([-_]\w)/g, g => g[1].toUpperCase())
            response.data = {
              type: ERROR.DUPLICATE,
              field
            }
          } else if (response.status === 401) {
            store.commit("auth/setLoggedIn", false, { root: true })
          }
          return Promise.resolve(response)
        }
      } else {
        store.commit("auth/setLoggedIn", false, { root: true })
      }
    })
  }
  return httpClient
}