/**
 * @file This file adds all constants to vue
 */
import Vue from "vue"
import CONSTANTS from "@/constants"
import MODULES from "@/constants/modules"
import USER_ACTIONS from "@/constants/user-actions"
import DECISIONS from "@/constants/decisions"
import TABLES from "@/constants/tables"
import LOCALES from "@/constants/locales"

Vue.prototype.$CONSTANTS    = CONSTANTS
Vue.prototype.$LOCALES      = LOCALES
Vue.prototype.$MODULES      = MODULES
Vue.prototype.$USER_ACTIONS = USER_ACTIONS
Vue.prototype.$DECISIONS    = DECISIONS
Vue.prototype.$TABLES       = TABLES