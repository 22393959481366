import { COGNITO_USER_POOL_DATA, COOKIE_STORAGE_DATA, COGNITO_AUTHENTICATION_MESSAGE, USER_ATTRIBUTE } from "@/constants"
import { CognitoUserPool, CognitoUser, CookieStorage, AuthenticationDetails, CognitoUserAttribute } from "amazon-cognito-identity-js"

export const getCurrentUser = (userPoolId, userPoolClientId) => {
  const cognitoUserPool = new CognitoUserPool(COGNITO_USER_POOL_DATA(userPoolId, userPoolClientId))
  return cognitoUserPool.getCurrentUser()
}

export const getSession = async (userPoolId, userPoolClientId) => {
  const user = getCurrentUser(userPoolId, userPoolClientId)
  if (user) {
    const getSessionPromise = new Promise(resolve => {
      user.getSession((error, session) => {
        if (error) {
          resolve(false)
        } else {
          resolve(session)
        }
      })
    })
    return await getSessionPromise
  }
  return false
}

export const authenticateUser = async (userPoolId, userPoolClientId, username, password, newPassword) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password
  })
  const poolData              = new CognitoUserPool(COGNITO_USER_POOL_DATA(userPoolId, userPoolClientId))
  const user                  = new CognitoUser({
    Username: username,
    Pool    : poolData,
    Storage : new CookieStorage(COOKIE_STORAGE_DATA)
  })

  const authenticateUserPromise = new Promise(resolve => {
    user.authenticateUser(authenticationDetails, {
      onSuccess          : result => resolve(result),
      newPasswordRequired: async () => {
        if (newPassword) {
          resolve(await completeNewPasswordChallenge(user, newPassword))
        } else {
          resolve(COGNITO_AUTHENTICATION_MESSAGE.NEW_PASSWORD_REQUIRED)
        }
      },
      onFailure: result => resolve(result)
    })
  })
  return await authenticateUserPromise
}

export const completeNewPasswordChallenge = async (user, password) => {
  if (user) {
    const completeNewPasswordChallengePromise = new Promise(resolve => {

      user.completeNewPasswordChallenge(password, {}, {
        onSuccess: result => resolve(result),
        onFailure: () => resolve(false)
      })
    })

    return await completeNewPasswordChallengePromise
  }
  return false
}

export const getUserData = async (userPoolId, userPoolClientId) => {
  const user = getCurrentUser(userPoolId, userPoolClientId)

  user.setSignInUserSession(await getSession(userPoolId, userPoolClientId))

  if (user) {
    const getUserDataPromise = new Promise(resolve => {
      user.getUserData((error, result) => {
        if (error) {
          resolve(false)
        } else {
          resolve(result)
        }
      })
    })

    return await getUserDataPromise
  }
  return false
}

export const updateUserAttributes = async (userPoolId, userPoolClientId, email, clearPreviousEmail)  => {
  const user = getCurrentUser(userPoolId, userPoolClientId)
  if(user) {
    user.setSignInUserSession(await getSession(userPoolId, userPoolClientId))

    if (clearPreviousEmail) {
      try {
        await new Promise((resolve, reject) => {
          user.deleteAttributes([USER_ATTRIBUTE.EMAIL], (error, result) => {
            if (error) {
              reject(error)
            } else {
              resolve(result)
            }
          })
        })
      } catch (error) {
        // handling the error
      }
    }
    const paramsList = []
    const params     = new CognitoUserAttribute({
      Name : USER_ATTRIBUTE.EMAIL,
      Value: email
    })
    paramsList.push(params)
    const updateUserAttributesPromise = new Promise(resolve => {

      user.updateAttributes(paramsList, (error, result) => {
        if (error) {
          resolve(false)
        } else {
          resolve(result)
        }
      })
    })
    return updateUserAttributesPromise
  }
}

export const verifyEmailUsingVerificationCode = async (userPoolId, userPoolClientId, code)  => {
  const user = getCurrentUser(userPoolId, userPoolClientId)
  if(user) {
    user.setSignInUserSession(await getSession(userPoolId, userPoolClientId))
    const verifyEmailPromise = new Promise(resolve => {

      user.verifyAttribute(USER_ATTRIBUTE.EMAIL, code, {
        onSuccess: result => resolve(result),
        onFailure: () => resolve(false)
      })
    })
    return verifyEmailPromise
  }
}

export const logout = async (userPoolId, userPoolClientId) => {
  const user = getCurrentUser(userPoolId, userPoolClientId)

  if (user) {
    const session = await getSession(userPoolId, userPoolClientId)
    if (session) {
      user.setSignInUserSession(session)
      const globalSignOutPromise = new Promise(resolve => {
        user.globalSignOut({
          onSuccess: result => resolve(result),
          onFailure: () => resolve(false)
        })
      })
      await globalSignOutPromise
    } else {
      user.signOut()
    }
  }
}

export default {
  authenticateUser,
  completeNewPasswordChallenge,
  getSession,
  getCurrentUser,
  getUserData,
  updateUserAttributes,
  logout,
  verifyEmailUsingVerificationCode
}