/**
 * @file It contains vuex message items module mutations to mutate the state variables.
 */
export default {
  setLoadingMessageItems: (state, loadingMessageItems) => {
    state.loadingMessageItems = loadingMessageItems
  },
  setMessageItems: (state, messageItems) => {
    state.messageItems = messageItems
  },
  resetMessageItems: state => {
    state.messageItems = new Array()
  },
  updateMessageItems: (state, messageItems) => {
    for (const messageItem of messageItems) {
      const index = state.messageItems.findIndex(stateMessageItem => stateMessageItem?.id === messageItem.id)
      if (index >= 0) {
        state.messageItems.splice(index, 1, messageItem)
      } else {
        state.messageItems.push(messageItem)
      }
    }
  },
  deleteMessageItems: (state, messageItems) => {
    state.messageItems = messageItems
  },
  setUploadingAttachments: (state, uploadingAttachments) => {
    state.uploadingAttachments = uploadingAttachments
  },
  setDownloadingFile: (state, downloadingFile) => {
    state.downloadingFile = downloadingFile
  }
}