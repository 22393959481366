/**
 * @file It contains all the vuex messages module state variables.
 */
export default {
  loadingMessages: undefined,
  messages       : new Array(),
  addingMessage  : false,
  messageAdded   : false,
  messageAddError: undefined,
  updatingMessage: false,
  messageUpdated : false,
  removingMessage: false,
  messageRemoved : false
}