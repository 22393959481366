export default {

  setLoadingFormInstances: (state, loadingFormInstances) => {
    state.loadingFormInstances = loadingFormInstances
  },

  setAddingFormInstance: (state, addingFormInstance) => {
    state.addingFormInstance = addingFormInstance
  },

  updateFormInstances: (state, formInstances) => {
    for (const formInstance of formInstances) {
      const index = state.formInstances.findIndex(stateFormInstance => stateFormInstance?.id === formInstance.id)
      if (index >= 0) {
        state.formInstances.splice(index, 1, { ...state.formInstances[index], ...formInstance })
      } else {
        state.formInstances.push(formInstance)
      }
    }
  },

  setFormInstances: (state, formInstances) => {
    state.formInstances = formInstances
  },

  setUpdatingFormInstance: (state, updatingFormInstance) => {
    state.updatingFormInstance = updatingFormInstance
  },

  setFormInstanceUpdated: (state, formInstanceUpdated) => {
    state.formInstanceUpdated = formInstanceUpdated
  }
}