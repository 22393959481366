export const PASSWORD_VALIDATION = {
  minimumCharactersPresent         : "33",
  minimumNumbersPresent            : "34",
  minimumUpperCaseCharactersPresent: "35",
  minimumLowerCaseCharactersPresent: "36",
  minimumSpecialCharactersPresent  : "37"
}

export default {
  PASSWORD_VALIDATION
}