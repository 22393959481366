/**
 * @file It exports a vuex store instance used by this application.
 */
import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"
import Cookies from "js-cookie"
import auth from "@/store/auth"
import channels from "@/store/channels"
import configurations from "@/store/configurations"
import languages from "@/store/languages"
import reports from "@/store/reports"
import messages from "@/store/messages"
import messageItems from "@/store/message-items"
import shared from "@/store/shared"
import formTemplates from "@/store/form-templates"
import formInstances from "@/store/form-instances"
import survey from "@/store/survey"

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    new VuexPersistence({
      restoreState: key => Cookies.getJSON(key),
      saveState   : (key, state) => {
        Cookies.set(key, state, {
          expires : null,
          domain  : window.location.host.split(":")[0],
          secure  : true,
          sameSite: "lax",
          path    : "/"
        })
      },
      modules: ["auth"]
    }).plugin
  ],
  modules: {
    auth,
    channels,
    configurations,
    languages,
    reports,
    messages,
    messageItems,
    shared,
    formTemplates,
    formInstances,
    survey
  }
})